import React, { ReactNode, useMemo } from "react";
import { AppLink } from "../../../../types";
import { Type1 } from "../../../graphics/zig-zag/type1";
import { Box } from "../../core/box";
import { Card } from "../../core/Card/Card";
import { Grid } from "../../core/Grid/Grid";
import { LinkStyleButton } from "../../core/LinkStyleButton/LinkStyleButton";
import { Typography } from "../../core/Typography/Typography";
import { Highlight, highlightText } from "../../utils/highlightText";

type ZigZagFeatureMedia = "tipo1"; // | "tipo2" | "tipo3" ... 

const mediaMap: Record<ZigZagFeatureMedia, ReactNode> = {
  tipo1: <Type1 />
};

export interface FeatureProps {
  mediaAlign?: "left" | "right";
  title?: string;
  subtitle?: string;
  bulletpoints?: { text: string; highlights: Highlight[] }[];
  cta?: AppLink;
  media: {
    graphics?: ZigZagFeatureMedia;
  };
}

export function ZigZagFeature(props: FeatureProps) {
  const { mediaAlign = "left", title, subtitle, bulletpoints = [], cta, media } = props;

  const MediaGraphics = useMemo<ReactNode>(() => {
    return media.graphics ? mediaMap[media.graphics] : null;
  }, [media]);

  const formattedBulletpoints = bulletpoints.map((point) => {
    const { text, highlights = [] } = point;

    if (highlights.length === 0) {
      return text;
    }

    return highlightText(text, highlights, (keyword) => (
      <Box as="strong" fontWeight={3}>
        {keyword}
      </Box>
    ));
  });

  const hasCtaLink = !!cta?.label && !!cta?.path;

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)">
      <Box
        gridColumn={["1 / span 12", "1 / span 12", `${mediaAlign === "left" ? 1 : 7} / span 6`]}
        gridRow={["2", "2", "1"]}
      >
        {MediaGraphics}
      </Box>
      <Card
        display="flex"
        flexDirection="column"
        gridColumn={["1 / span 12", "1 / span 12", "auto / span 6"]}
        gridRow="1"
      >
        <Typography variant="h3">{title}</Typography>
        {subtitle && (
          <Typography variant="body1" marginTopSpacing={2} flexGrow={1}>
            {subtitle}
          </Typography>
        )}
        {formattedBulletpoints.map((text, idx) => (
          <Typography key={idx} paddingTopSpacing={2} variant="body1">
            {text}
          </Typography>
        ))}
        {hasCtaLink && (
          <LinkStyleButton
            label={cta.label}
            path={cta.path}
            external={cta.external}
            typographyProps={{ marginTopSpacing: [2, 2, 0] }}
          />
        )}
      </Card>
    </Grid>
  );
}
