import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../../components/layout";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../../components/seo";
import { IndustryPageData, ZigZagSectionDataWater } from "../../data/industry";
import { ZigZagSection } from "../../components/rebrand/sections/ZigZag/ZigZag";

const { initialNavigationTheme, industryWaterPageTitleData } =
IndustryPageData;

interface WaterPageProps extends PageProps {
  data: Queries.WaterPageQuery;
}

export const pageQuery = graphql`
  query WaterPage {
    site {
      siteMetadata {
        pages {
            industry {
                water {
                    title
                    description
                    keywords
                }
            }
        }
      }
    }
  }
`;

export default function IndustryWaterPage(props: WaterPageProps) {
  const {
    location: { pathname },
    data:  { site }
  } = props;

  const { title, description, keywords } = site?.siteMetadata?.pages?.industry?.water as Queries.IndustryWaterPage;

  return (
    <Layout initialNavigationTheme={initialNavigationTheme}>
      <SEO title={title} description={description} keywords={[...keywords]} pathname={pathname} />
      <PageHeaderSection theme={industryWaterPageTitleData.theme} title={industryWaterPageTitleData.title} />

      <ZigZagSection {...ZigZagSectionDataWater} />

    </Layout>
  );
}
