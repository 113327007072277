import React, { ReactElement, ReactNode } from "react";

export type Highlight = {
  keyword: string;
  accent?: string;
};

export function highlightText(
  text: string,
  highlights: Highlight[],
  highlightFormatter: (text: string, accent?: string) => ReactElement,
): ReactNode {
  const { text: ending, nodes } = highlights.reduce<{ text: string; nodes: ReactNode[] }>(
    (prev, curr) => {
      const { text, nodes } = prev;
      const { keyword, accent } = curr;

      const [textStart, textEnd] = text.split(keyword);

      nodes.push(textStart);
      nodes.push(highlightFormatter(keyword, accent));

      return {
        text: textEnd,
        nodes,
      };
    },
    { text, nodes: [] },
  );

  return (
    <>
      {[...nodes, ending].map((node, idx) => (
        <React.Fragment key={idx}>{node}</React.Fragment>
      ))}
    </>
  );
}
