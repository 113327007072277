import React, { ReactNode } from "react";
import { AppLink } from "../../../../types";
import { BoxProps, Box } from "../../core/box";
import { LinkStyleButton } from "../../core/LinkStyleButton/LinkStyleButton";
import { Typography, TypographyProps } from "../../core/Typography/Typography";
import { Caption, CaptionProps } from "../Caption/Caption";

const config = {
  large: {
    titleFontSize: [6, 8],
    subtitleTopSpacing: [2.5, 3],
    captionBottomSpacing: 3,
  },
  medium: {
    titleFontSize: [6, 7],
    subtitleTopSpacing: 2.5,
    captionBottomSpacing: 2.5,
  },
};

export interface SectionHeaderProps extends BoxProps {
  captionProps?: CaptionProps;
  title: ReactNode;
  subtitle?: ReactNode;
  subtitleProps?: TypographyProps;
  variant?: "large" | "medium";
  cta?: AppLink;
}

export function SectionHeader(props: SectionHeaderProps) {
  const { title, subtitle, subtitleProps, captionProps, variant = "large", cta, ...otherProps } = props;

  const { titleFontSize, subtitleTopSpacing, captionBottomSpacing } = config[variant];

  const hasCta = cta?.label && cta?.path;

  return (
    <Box display="flex" flexDirection="column" textAlign="left" width={["100%", "100%", 4 / 5]} {...otherProps}>
      {captionProps && captionProps.text && <Caption marginBottomSpacing={captionBottomSpacing} {...captionProps} />}{" "}
      <Typography variant="h2" fontSize={titleFontSize}>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          as="p"
          variant="subheading"
          fontSize={[1, 4]}
          marginTopSpacing={subtitleTopSpacing}
          {...subtitleProps}
        >
          {subtitle}
        </Typography>
      )}
      {hasCta && (
        <Box marginTopSpacing={[3, 4]}>
          <LinkStyleButton label={cta.label} path={cta.path} external={cta.external} />
        </Box>
      )}
    </Box>
  );
}
