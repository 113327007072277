import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Box } from "../../rebrand/core/box";

export function Type1() {
  return (
    <Box position="relative" overflowX="hidden">
      {/* <StaticImage src="#" alt="Type1" /> */}
    </Box>
  );
}
