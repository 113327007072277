import React, { ReactNode, useCallback } from "react";
import { Grid } from "../../core/Grid/Grid";
import { SectionHeader } from "../../ui/SectionHeader/SectionHeader";
import { Section } from "../Section/Section";
import { ZigZagFeature, FeatureProps } from "./Feature";

export interface ZigZagSectionProps {
  features: FeatureProps[];
  initialAlign?: "left" | "right";
  header?: {
    title: string;
    subtitle?: string | ReactNode;
    caption?: string;
  };
}

export function ZigZagSection(props: ZigZagSectionProps) {
  const { features, initialAlign = "left", header } = props;

  const getAlignValue = useCallback(
    (idx: number) => {
      const isOdd = idx % 2 === 1;

      if (initialAlign === "left") {
        return isOdd ? "left" : "right";
      }

      return isOdd ? "right" : "left";
    },
    [initialAlign],
  );

  return (
    <Section>
      <Grid>
        {header && (
          <SectionHeader
            title={header.title}
            subtitle={header?.subtitle}
            captionProps={{ text: header.caption, accent: "text.blue" }}
            paddingLeftSpacing={[0, 0, 6]}
            marginBottomSpacing={5}
            width={10 / 12}
          />
        )}
        {features.map((feature, idx) => (
          <ZigZagFeature key={idx} mediaAlign={getAlignValue(idx)} {...feature} />
        ))}
      </Grid>
    </Section>
  );
}
