import React, { ReactNode } from "react";
import { SpacingProps } from "../../../../theme/spacing";
import { Box } from "../../core/box";
import { Typography } from "../../core/Typography/Typography";

export interface CaptionProps extends SpacingProps {
  text?: string;
  accent?: string;
  Icon?: ReactNode;
}

export function Caption(props: CaptionProps) {
  const { text, accent = "text.loud", Icon, ...otherProps } = props;

  return (
    <Box display="flex" alignItems="center" color={accent} {...otherProps}>
      {Icon && (
        <Box
          display="inline-flex"
          flexGrow={0}
          width={["1.5rem", "2.25rem"]}
          height={["1.5rem", "2.25rem"]}
          marginRightSpacing={2}
        >
          {Icon}
        </Box>
      )}
      <Typography variant="h4" color="inherit" fontSize={[1, 4]} fontWeight={1}>
        {text}
      </Typography>
    </Box>
  );
}
