import { InvestorsSectionProps } from "../components/rebrand/sections/Investors/InvestorsSection";
import { LeadershipSectionProps } from "../components/rebrand/sections/Leadership/LeadershipSection";
import { LocationsMapSectionProps } from "../components/rebrand/sections/LocationsMap/LocationsMapSection";
import { PageHeaderSectionProps } from "../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SplitContentSectionProps } from "../components/rebrand/sections/SplitContent/SplitContent";
import type { ZigZagSectionProps } from "../components/rebrand/sections/ZigZag/ZigZag";
import { AppThemeVariant } from "../types";
import { links } from "./site";

const initialNavigationTheme: AppThemeVariant = "dark";

const industryWaterPageTitleData: PageHeaderSectionProps = {
  theme: "dark",
  title: "Agua",
};

export const ZigZagSectionDataWater: ZigZagSectionProps = {
  header: {
    title: "Ten el control y visualización de todo el proceso",
    subtitle:
      "Identifica tus puntos de funcionamiento óptimo, mide la energía consumida, reduce fugas, entre otros, debido al desarrollo de sistemas de control para los diferentes procesos productivos y la obtención de información en tiempo real."
  },
  initialAlign: "right",
  features: [
    {
      title: "Título 1",
      subtitle:
        "Texto",
      media: {
        graphics: "connectRemote",
      },
    },
    {
      title: "Título 2",
      subtitle:
        "Texto",
      media: {
        graphics: "client",
      },
    },
    {
      title: "Título 3",
      subtitle:
        "Texto",
      media: {
        graphics: "engineering",
      },
    },
  ],
};


const industryMiningPageTitleData: PageHeaderSectionProps = {
  theme: "dark",
  title: "Minería",
};

export const ZigZagSectionDataMining: ZigZagSectionProps = {
  header: {
    title: "Alta eficiencia en procesos mineros",
    subtitle:
      "Controla máquinas a distancia y conoce el desarrollo e impacto de sus tecnologías con la implementación de sistemas de control y monitoreo en línea, logrando procesos operacionales precisos y seguros."
  },
  initialAlign: "right",
  features: [
    {
      title: "Título 1",
      subtitle:
        "Texto",
      media: {
        graphics: "connectRemote",
      },
    },
    {
      title: "Título 2",
      subtitle:
        "Texto",
      media: {
        graphics: "client",
      },
    },
    {
      title: "Título 3",
      subtitle:
        "Texto",
      media: {
        graphics: "engineering",
      },
    },
  ],
};


const industryRetailPageTitleData: PageHeaderSectionProps = {
  theme: "dark",
  title: "Retail",
};

export const ZigZagSectionDataRetail: ZigZagSectionProps = {
  header: {
    title: "Tecnología para ventas más eficientes",
    subtitle:
      "Consigue una alta eficiencia operativa, agiliza los procesos internos y reduce costos, a través de la incorporación de soluciones que permitan la automatización de sistemas, para entregar a tus consumidores una experiencia de servicio única."
  },
  initialAlign: "right",
  features: [
    {
      title: "Título 1",
      subtitle:
        "Texto",
      media: {
        graphics: "connectRemote",
      },
    },
    {
      title: "Título 2",
      subtitle:
        "Texto",
      media: {
        graphics: "client",
      },
    },
    {
      title: "Título 3",
      subtitle:
        "Texto",
      media: {
        graphics: "engineering",
      },
    },
  ],
};


const industryProductivePageTitleData: PageHeaderSectionProps = {
  theme: "dark",
  title: "Industria Productiva",
};

export const ZigZagSectionDataIndustryProductive: ZigZagSectionProps = {
  header: {
    title: "Mejores tiempos en la cadena productiva",
    subtitle:
      "Logra el control constante, preciso y en tiempo real para niveles líquidos, temperatura, potencia entre otros, con el fin de obtener una fabricación eficiente, precisa y de alta calidad."
  },
  initialAlign: "right",
  features: [
    {
      title: "Título 1",
      subtitle:
        "Texto",
      media: {
        graphics: "connectRemote",
      },
    },
    {
      title: "Título 2",
      subtitle:
        "Texto",
      media: {
        graphics: "client",
      },
    },
    {
      title: "Título 3",
      subtitle:
        "Texto",
      media: {
        graphics: "engineering",
      },
    },
  ],
};


export const IndustryPageData = {
  initialNavigationTheme,

  industryWaterPageTitleData,

  industryMiningPageTitleData,

  industryRetailPageTitleData,

  industryProductivePageTitleData
};
